export const columns11 = [
  { id: 'emtpy', label: '', minWidth: 170 },

  { id: '20181', label: '2018', minWidth: 100 },
  { id: '20191', label: '2019', minWidth: 100 },
  { id: 'Percentage1', label: 'Percentage change in 2019 over 2018', minWidth: 150 },

  { id: '20182', label: '2018', minWidth: 100 },
  { id: '20192', label: '2019', minWidth: 100 },
  { id: 'Percentage2', label: 'Percentage change in 2019 over 2018', minWidth: 150 },

  { id: '20183', label: '2018', minWidth: 100 },
  { id: '20193', label: '2019', minWidth: 100 },
  { id: 'Percentage3', label: 'Percentage change in 2019 over 2018', minWidth: 150 },
];

export const rows11 = [
  {
    emtpy: 'Less than 5 years',
    20181: 165391,
    20191: 155573,
    Percentage1: -5.9,
    20182: 49363,
    20192: 48979,
    Percentage2: -0.8,
    20183: 171881,
    20193: 162900,
    Percentage3: -5.2,
  },
  {
    emtpy: '% share of total (Less than 5 years)',
    20181: 35.4,
    20191: 34.6,
    20182: 32.6,
    20192: 32.4,
    20183: 36.6,
    20193: 36.1,
  },
  {
    emtpy: '5-10 years',
    20181: 130486,
    20191: 122251,
    Percentage1: -6.3,
    20182: 40145,
    20192: 39515,
    Percentage2: -1.6,
    20183: 135253,
    20193: 127490,
    Percentage3: -5.7,
  },
  {
    empty: '% share of total (5-10 years)',
    20181: 27.9,
    20191: 27.2,
    20182: 26.5,
    20192: 26.1,
    20183: 28.8,
    20193: 28.2,
  },
  {
    emtpy: '10-15 years',
    20181: 61277,
    20191: 55944,
    Percentage1: -8.7,
    20182: 20608,
    20192: 19081,
    Percentage2: -7.4,
    20183: 61912,
    20193: 57335,
    Percentage3: -7.4,
  },
  {
    empty: '% share of total (10-15 years)',
    20181: 13.1,
    20191: 12.5,
    20182: 13.6,
    20192: 12.6,
    20183: 13.2,
    20193: 12.7,
  },

  {
    emtpy: '15 years & above',
    20181: 44628,
    20191: 49475,
    Percentage1: 10.9,
    20182: 16463,
    20192: 18660,
    Percentage2: 13.3,
    20183: 42548,
    20193: 45483,
    Percentage3: 6.9,
  },
  {
    empty: '% share of total (15 years & above)',
    20181: 9.6,
    20191: 11.0,
    20182: 10.9,
    20192: 12.3,
    20183: 9.1,
    20193: 10.1,
  },
  {
    emtpy: 'Age not known',
    20181: 64946,
    20191: 65454,
    Percentage1: 0.8,
    20182: 24740,
    20192: 24774,
    Percentage2: 0.1,
    20183: 57524,
    20193: 57878,
    Percentage3: 0.6,
  },
  {
    empty: '% share of total (Age not known)',
    20181: 13.9,
    20191: 14.6,
    20182: 16.3,
    20192: 16.4,
    20183: 12.3,
    20193: 12.8,
  },
];
