import { Autocomplete, Box, Button, Checkbox, Chip, Grid, IconButton, InputLabel, Paper, TextField, ThemeProvider, ToggleButton, ToggleButtonGroup, Tooltip, Typography, createTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LogoImage from "assets/img/logo.png"
import AvahTransparent from 'assets/img/avah_tranparent .png'
import { getYear } from 'date-fns'
import './index.scss'
import { useMobileResponsive } from 'hooks/useMobileResponsive'
import CreateTextFields from 'components/common/Textfield'
import URL from 'url/apiURL'
import { useFetch, useFetchFunction } from 'hooks/useFetch'
import { getCities, getStates, requiredTextfield } from 'utils/customFunctions'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ForgotPassword from './Components/ForgotPassword'
import FilepondImageUploader  from 'components/common/FilePondImageUploader'
import axios from 'axios'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import { SuccessLoader } from './Components/SuccessLoader'
// import { FilepondImageUploader } from 'components/common/FilePondImageUploader'
const {getAllCitiesPerState,getAllBrandsMultiSelect} = URL.LOGIN_REGISTER

const RaeesLoginComponent = () => {
    const [formData, setFormData] = useState({isBrandError:false,success:false,error:false});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const {snackbar,loadingIndicator,fetchData} = useFetchFunction()
    const [login,setLogin] = useState(true)
    const [activeButton, setActiveButton] = useState('customer');
    const currentYear = getYear(new Date());
    const {isMobile} = useMobileResponsive()
    const location = useLocation();
    const navigate = useNavigate()
    const isAdminPage = location.pathname.includes('/admin');
    const {data:cityData} = useFetch(getAllCitiesPerState)
    const [citiesAndState, setCitiesAndState] = useState({ state: [], cities: [] })
    const [forgotPassword, setForgotPasssword] = useState(false)
    const [files, setFiles] = useState({})
    const [fileError,setFileError] = useState(false)
    const [fileRequiredError,setFileRequiredError] = useState(false)
    console.log(fileRequiredError)
    const {data:multiSelectBusinessData} = useFetch(getAllBrandsMultiSelect)
    let mappedBrandName = multiSelectBusinessData?.data?.map((val)=>val?.value)

    // const form = new FormData()

    const handleFileChange = (e)=>{
        setFiles(e.target.files[0])
    }
    //TO GET ALL THE STATES
    useEffect(() => {
        if (cityData?.result?.length) {
            let listOfStates = getStates(cityData?.result)
            setCitiesAndState({ state: listOfStates })
        }
    }, [cityData])

    //TO GET CITIES BASED ON SELECTED STATE
    useEffect(() => {
        if (formData.state) {
            let citiesList = getCities(formData.state, cityData?.result)
            setCitiesAndState((prev) => ({ ...prev, cities: citiesList }))
        }
    }, [formData.state])

    const handleButtonClick = (buttonText) => {
        setFormData({})
        setActiveButton(buttonText);
      };

    const handleFieldChange = (fieldName, value) => {
        setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
    };

    const loginFunction = async()=>{
        let payload ={...formData,role:activeButton}
        if(isAdminPage){
            payload = {...formData,role:"admin"}
        }
        setIsSubmitted(true)
        let isRequired = requiredTextfield(loginTextfield.slice(1,3),formData)
        // console.log(isRequired,formData)
        if(isRequired) {
            setTimeout(() => {
                setIsSubmitted(false)
            }, [2000]);
            return
        }

        let url = ''
        if(payload.role ==='admin') url = URL.LOGIN_REGISTER.login_admin
        if(payload.role ==='service provider') url = URL.LOGIN_REGISTER.login_service_provider
        if(payload.role ==='customer') url = URL.LOGIN_REGISTER.login_customer
        let {data:loginDetails} = await fetchData({url,method:"POST",payload})

        if(loginDetails){
            // if(true){
            if(payload.role==='admin'){
                setTimeout(() => {
                    navigate('/admin/home');
                }, 0);
                localStorage.setItem('TYPE_OF_USER', "1");
                localStorage.setItem('role', "Administrator");
                localStorage.setItem('profile_name', loginDetails.profile_name);
                localStorage.setItem('access_tokenSP', loginDetails.token);
                localStorage.setItem('isLoggedIn', "true");  
            }
            else if (payload.role=='service provider'){
                setTimeout(() => {
                    navigate('/serviceProvider/home');
                    navigate(0)
                    // window.location.reload();
                }, 0);
                // localStorage.setItem('TYPE_OF_USER', loginDetails.TYPE_OF_USER);       
                localStorage.setItem('TYPE_OF_USER', loginDetails.TYPE_OF_USER);
                localStorage.setItem('role', loginDetails.designation);
                // localStorage.setItem('role', "Service Provider");
                localStorage.setItem('access_tokenSP', loginDetails.token);
                localStorage.setItem('isLoggedInSP', "true");
                localStorage.setItem('sp_id', loginDetails.sp_id);  
                localStorage.setItem('profile_name', loginDetails.profile_name);
                localStorage.setItem('permission_granted',loginDetails.permission_granted)
                localStorage.setItem('business_name',loginDetails.business_name)

            }
            else if(payload.role==='customer'){
                setTimeout(() => {
                    navigate('/customer/home');
                }, 0);
                localStorage.setItem('TYPE_OF_USER', "3");
                localStorage.setItem('role', "Customer");
                localStorage.setItem('customer_id', loginDetails.customer_id);
                localStorage.setItem('customer_email', loginDetails.customer_email);
                localStorage.setItem('access_tokenSP', loginDetails.token);
                localStorage.setItem('isLoggedInSP', "true"); 
                localStorage.setItem('profile_name', loginDetails.profile_name); 
            }            
        }
        setIsSubmitted(false)
        setFormData({})
    }

    const registerFunction = async(e)=>{
        e?.preventDefault()
        let payload ={...formData,role:activeButton,approval_status:false,sp_status:"inactive"}
        setIsSubmitted(true)
        let isRequired = requiredTextfield(registerTextfield,formData)
        // console.log(payload.role)
        if(payload.role==='service provider'){
            // console.log(isRequired,files,formData?.serviced_brands)
            if(isRequired || !files?.name || files?.type!=='application/pdf' || formData?.serviced_brands?.length===0 || !formData?.serviced_brands) {
                // console.log(isRequired,files,formData?.serviced_brands)
                if(isRequired){
                    setTimeout(() => {
                        setIsSubmitted(false)
                    }, [2000]);
                }
                if(formData?.serviced_brands?.length===0 || !formData?.serviced_brands){
                    setFormData((prev)=>({...prev,isBrandError:true}))
                    setTimeout(() => {
                        setFormData((prev)=>({...prev,isBrandError:false}))
                      }, [2000])
                    // return
                }
                // console.log(files,!files?.name)
                if(files?.name && files?.type!=='application/pdf'){
                    setTimeout(() => {
                        setFileError(false)
                        // setFiles({})
                    }, [2000])
                    setFileError(true)
                    // return
                }
                else if(!files?.name){
                    console.log('yes')
                    setFileRequiredError(true)
                    setTimeout(()=>{setFileRequiredError(false)},2000)
                }
                // setFileError(false)
                // setFileRequiredError(false)
                return
            }
        }
        else{
            console.log('cust')
            if(isRequired){
                setTimeout(() => {
                    setIsSubmitted(false)
                }, [2000]);
                return
            }
        }
        // if(isRequired || (!files?.name || files?.type!=='application/pdf') || formData?.serviced_brands?.length===0 || !formData?.serviced_brands) {
        //     if(payload.role==='service provider'){
        //         if(formData?.serviced_brands?.length===0 || !formData?.serviced_brands || files?.type!=='application/pdf'){
        //             console.log(formData?.serviced_brands)
        //             if(formData?.serviced_brands?.length===0 || !formData?.serviced_brands){
        //                 setFormData((prev)=>({...prev,isBrandError:true}))
        //                 setTimeout(() => {
        //                     setFormData((prev)=>({...prev,isBrandError:false}))
        //                   }, [2000])
        //                 // return
        //             }
        //             if(files?.name && files?.type!=='application/pdf'){
        //                 setTimeout(() => {
        //                     setFileError(false)
        //                     // setFiles({})
        //                 }, [2000])
        //                 setFileError(true)
        //                 // return
        //             }
        //             else if(!files?.name){
        //                 setFileRequiredError(true)
        //                 setTimeout(()=>{setFileRequiredError(false)},2000)
        //             }
        //             else {
        //                 setFileError(false)
        //                 setFileRequiredError(false)
        //             }
        //             // return
        //         }
        //     }
            // CUSTOMER
            // else{
            //     if(isRequired){
            //         setTimeout(() => {
            //             setIsSubmitted(false)
            //         }, [2000]);
            //         return
            //     }
            // } 
            // setTimeout(() => {
            //     setIsSubmitted(false)
            // }, [2000]);      
            // return
        // }


        let url = ''
        if(payload.role ==='customer') url = URL.LOGIN_REGISTER.register_customer
        if(payload.role === 'service provider')url = URL.LOGIN_REGISTER.register_service_provider

        // if(payload.role ==='service provider'){
        //     payload = form
        // }
        if(payload.role === 'service provider'){
            let formData = new FormData()
            formData.append('business_document', files)
            // let jsonBlob = new Blob([JSON.stringify(payload)],{
            // type: 'application/json'
            // })
            for(let key in payload){
                if(key==='serviced_brands'){
                    formData.append(key,JSON.stringify(payload[key]))
                }
                else{
                    formData.append(key,payload[key])
                }
            }      
            const config = {
                headers : {
                    "Content-Type" : "multipart/form-data"
                }
            }
            const {data,status} = await axios.post(url,formData,config)

            if(data && status === 200){
                setTimeout(()=>{setFormData({success:false,error:false}); setLogin(!login)},2000)
                setFormData({success:true,error:false})
            }
            else{
                setTimeout(()=>{setFormData({success:false,error:false}); setLogin(!login)},2000)
                setFormData({success:false,error:true})
            }

        }
        else {
            let {data:regesterDetails} = fetchData({url,method:"POST",payload})
            if(regesterDetails){
                setTimeout(()=>{setFormData({success:false,error:false}); setLogin(!login)},2000)
                setFormData({success:false,error:false})
                setLogin(!login)
            }
            else{
                setTimeout(()=>{setFormData({success:false,error:false}); setLogin(!login)},2000)
                setFormData({success:false,error:false})
            }
        }
       
        setIsSubmitted(false)
        // setFormData({})
        setTimeout(()=>setFormData({}),2000)
    }
    let loginTextfield = [
        {
            label:"Name",
            name:"name",
            type:"text",
            fullWidth:true,
            required:true,
            errormessage:'Name is Required'

        },
        {
            label:"Email",
            name:"email",
            type:"text",
            fullWidth:true,
            required:true,
            errormessage:'Email is Required'

        },
        {
            label:"Password",
            name:"password",
            type:"password",
            fullWidth:true,
            required:true,
            errormessage:'Password is Required'
        },
        {
            label:"Confirm Password",
            name:"cnfPassword",
            type:"password",
            fullWidth:true,
            required:true,
            errormessage:'Confirm Password is Required'
        },
    ]

    const businessTypes = [
        {
            label:'Sole Proprietorship',
            value:'Sole Proprietorship'
        },
        {
            label:'Partnership',
            value:'Partnership'
        },
        {
            label:'Limited Liability Partnership',
            value:'Limited Liability Partnership'
        },
        {
            label:'Private Limited Companies',
            value:'Private Limited Companies'
        },
        {
            label:'Public Limited Companies',
            value:'Public Limited Companies'
        },
        {
            label:'One-Person Companies',
            value:'One-Person Companies'
        },        
        {
            label: 'Section 8 Company',
            value: 'Section 8 Company'
        },
        {
            label:'Joint-Venture Company',
            value:'Joint-Venture Company'
        }
      ];

    let registerTextfield = [
        {
            label:"Your Name",
            name:"name",
            type:"text",
            required:true,
            errormessage:'Name is Required'
        },
        {
            label:"Email ID",
            name:"email",
            type:"email",
            required:true,
            errormessage:'Email is Required'
        },
        {
            label:"Business Type",
            name:"business_type",
            type:"text",
            select:true,
            selectArray:businessTypes,
            required:true,
            errormessage:'Select Business Type'
        },
        {
            label:"Business Name",
            name:"business_name",
            type:"text",
            required:true,
            errormessage:'Business Name is Required'
        },
        {
            label:"Business Contact Number",
            name:"business_contact",
            type:"number",
            required:true,
            fullWidth: true,
            errormessage:'Business Contact is Required'
        },
        {
            label:"Business Address",
            name:"business_address",
            type:"text",
            row:2,
            multiline:true,
            required:true,
            errormessage:'Address is Required'
        }, 
        {
            label: 'State',
            name: "state",
            type: 'text',
            fullWidth: true,
            select:true,
            selectArray:citiesAndState?.state,
            size:true,
            required:true,
            errormessage:'Select State'
        },
        {
            label: 'City',
            name: "city",
            type: 'text',
            fullWidth: true,
            select:true,
            selectArray:citiesAndState?.cities,
            size:true,
            required:true,
            errormessage:'Select City'
        },   
        {
            label:"Pincode",
            name:"pin_code",
            type:"number",
            required:true,
            fullWidth: true,
            errormessage:'Please Enter Your Pincode'
        },      
        {
            label:"Password",
            name:"password",
            type:"password",
            required:true,
            errormessage:'Password is Required'
        },        
        {
            label:"Confirm Password",
            name:"cnfPassword",
            type:"password",
            required:true,
            errormessage:'Please Reconfirm Your Password'
        }
    ]


  return (
    <>  
    {/* This is a floating Navbar */}
    {isAdminPage ?
        // IF YOURE IN ADMIN PAGE
        isMobile?
            // FOR MOBILE PUT THE LOGO IN CENTER
            <Box><img src={LogoImage} alt="logo Img" /></Box>
            :
            //FOR LAPTOP PUT IT IN A DIFFERENT PLACE
            <Box className='floating-navbar-container'>
                <Grid className='thick-container'></Grid>
                <Grid className='navbar-floating-img-container'>
                    <img src={LogoImage} alt="logo Img" />
                </Grid>
            </Box>
        :
        //IF YOURE  IN REGULAR PAGE IE=> NOT ADMIN AND ONLY IF NOT IN MOBILE THEN SHOW
        !isMobile && (
            <Box className='floating-navbar-container'>
                <Grid className='thick-container'></Grid>
                <Grid className='navbar-floating-img-container'>
                    <Link to={'/'}><img src={LogoImage} alt="logo Img" /></Link>
                </Grid>
                <Box className='sticky-navbar-container'>
                <Box className="navbar-flex">
                    <Box><Link to={'/'}>Home</Link></Box>
                            <Box>
                                <Box>About Us</Box>
                                <Box >
                                Coined from a Sanskrit word "<span className="purple">A Vahan</span> ". The founder had a vision to open a garage out of passion for cars. <br/> He had experienced a lack of services for discontinued vehicles and found it very difficult to find the right service executive for the same. Though local mechanics were an option, how to identify and be assured that he is the right guy for your vehicle service? Every startup originates from a problem faced by the public but the solution to that problem lies in the answer of an entrepreneur and there came a vision which was to provide transparency and clarity and connect car owners to their right service provider.<br/> The unauthorized service market is very huge and that's where the founder saw the opportunity to set up a link between car owners and local mechanics. There are a lot of gray areas and no record when and where vehicles are maintained by the car owner through unauthorized local mechanics and there is no accountability for the same. Believing to just have trust in your mechanic and hand over your second home won't suffice this solution when it comes to driving that vehicle on a daily basis.<br/> We need a setup to look into the same and strengthen that trust into reality as this is the need of the current situation. This platform is the solution that caters to the problem rather than an obstacle which we face on our daily chores. Consider it as a medicine from a certified and recognized place which has no room for ambiguity.
                                </Box>
                            </Box>

                            <Box><Link to={'/whatWeDo'}>What we do</Link></Box>
                            <Box><button onClick={()=>{setLogin(!login);setFormData({});setFiles({});setFileError(false);setFileRequiredError(false)}}>{login?'SIGN UP':"LOGIN"}</button></Box>
                        </Box>
                    {/* </> */}
                </Box>
            </Box>
        )
                
    }
    <Grid container className='login'>
        <Grid item className="left-side-container">
            {
                // THIS IS LOGIN FOR CUSTOMER, DEALER, SERVICE PROVIDER, ADMIN AND REGISTER FOR CUSTOMER
                login || (!login && activeButton =='customer')
                    ?
                        <Box className='form-container'>
                       <Grid container className='login-form'>

                            {/* ONLY SHOW IF ITS IN MOBILE VIEW FOR LOGO AND BUTTON AND DONT SHOW IF ITS IN ADMIN*/}
                            {isMobile && (
                            <Box className='mobile-logo-sign-up'>
                                <Box>{isAdminPage ? <></>:<Link to={'/'}><img src={LogoImage} alt="logo Img" ></img></Link>}</Box>
                                <Box>{isAdminPage ? <></>: <button onClick={()=>{setLogin(!login);setFormData({});setFiles({});setFileError(false);setFileRequiredError(false)}} className='black-button'>{login?'SIGN UP':"LOGIN"}</button>}</Box>
                            </Box>
                            )}

                            {/* ONLY SHOW IF LOGIN */}
                            {isAdminPage ? <></> : login  && (
                            <Box className='three-buttons'>
                                <button className={activeButton==='customer'?'active':'inactive'} onClick={()=>handleButtonClick('customer') }>Customer</button>
                                <button className={activeButton==='service provider'?'active':'inactive'} onClick={()=>handleButtonClick('service provider')}>Service Provider</button>
                                {/* <button  className={activeButton==='dealers'?'active':'inactive'}onClick={()=>handleButtonClick('dealers')}>Dealer</button> */}
                            </Box>
                            )}
                            {forgotPassword ?
                                <ForgotPassword goBack={()=>setForgotPasssword(false)} user={isAdminPage ?'admin': activeButton==='customer' ?'customer' : activeButton==='service provider'? 'serviceProvider':isAdminPage ?'admin':''}/>
                                :
                                <>
                                    <Box className='welcome'>{login ? 'Welcome back' : 'Welcome'}</Box>
                                    <Box className='enter-details'>{login ? 'Enter your email and password to sign in' : 'Enter your credentials to register'}</Box>
                                    <Box className='textfields'>
                                        <Box className='smaller-container'>
                                            <CreateTextFields fields={login ? loginTextfield.slice(1, 3) : loginTextfield} formField={formData} onChange={handleFieldChange} isSubmitted={isSubmitted} />
                                            {/* ONLY SHOW IF LOGIN AND DONT SHOW IF IN ADMIN*/}
                                            {login && (<Box className='remember-me-container'>
                                                <Box className='checkbox-container'>
                                                    {/* <Box><Checkbox/></Box>
                                    <Box>Remember Me</Box> */}
                                                </Box>
                                                <Box><Button color='options' onClick={()=>setForgotPasssword(true)}>Forgot password ?</Button></Box>
                                            </Box>)}
                                            <Box className='signup-register-button'><Button onClick={login ? loginFunction : registerFunction}>{login ? 'LOGIN' : 'REGISTER'}</Button></Box>
                                        </Box>
                                    </Box>
                                </>
                            }


                       </Grid>
                       </Box>
                    :
                    // YOURE IN REGISTER, THIS IS FOR DEALER OR SERVICE PROVIDER ,SINCE ADMIN DONT HAVE AND CUSTOMER COVERED INSIDE THE LOGIN
                        
                        <Box className='register-container'>
                        <form onSubmit={registerFunction}>

                            {isMobile && (
                            <Box className='mobile-logo-sign-up'>
                                <Box>{isAdminPage ? <></>:<Link to={'/'}><img src={LogoImage} alt="logo Img" ></img></Link>}</Box>
                                <Box>{isAdminPage ? <></>: <button onClick={()=>{setLogin(!login);setFormData({});setFiles({});setFileError(false);setFileRequiredError(false)}} className='black-button'>{login?'SIGN UP':"LOGIN"}</button>}</Box>
                            </Box>
                            )}
                            <Box className='welcome'>Welcome {activeButton==='service provider'?'Service Provider':activeButton==='dealers'?'Dealer':''}</Box>
                            <Box className='first-row'>
                                <CreateTextFields fields={registerTextfield.slice(0,1)} formField={formData} onChange={handleFieldChange} isSubmitted={isSubmitted}/>
                                <Box>
                                    <InputLabel sx={{ mb: 1 }}>Brand Service*</InputLabel>
                                    <Autocomplete
                                    freeSolo
                                    autoComplete
                                    multiple
                                    id="fixed-tags-demo"
                                    value={ formData?.serviced_brands}
                                    options={mappedBrandName || []}
                                    onChange={(event, value) =>setFormData((prevData) => ({ ...prevData, ['serviced_brands']: value }))} 
                                    // onSelect={(e,v)=>console.log(e,v,'raees')}
                                    getOptionLabel={(option)=>option}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                        <Chip
                                            label={option|| ''}
                                            {...getTagProps({ index })}
                                        />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField 
                                        {...params} 
                                        size='small'                                 
                                        error={formData?.isBrandError}
                                        helperText={formData?.isBrandError ? 'Must Select One Brand Atleast' : ''}/>
                                    )}

                                    />
                                </Box>

                            </Box>
                            <Box className='second-row'>
                                <CreateTextFields fields={registerTextfield.slice(1,3)} formField={formData} onChange={handleFieldChange} isSubmitted={isSubmitted}/>
                            </Box>
                            <Box className='third-row'>
                                <CreateTextFields fields={registerTextfield.slice(3,5)} formField={formData} onChange={handleFieldChange} isSubmitted={isSubmitted}/>
                            </Box>
                            <Box className='fourth-row'>
                                <CreateTextFields fields={registerTextfield.slice(5,6)} formField={formData} onChange={handleFieldChange} isSubmitted={isSubmitted}/>
                            </Box>
                            <Box className='fifth-row'>
                                <CreateTextFields fields={registerTextfield.slice(6,8)} formField={formData} onChange={handleFieldChange} isSubmitted={isSubmitted}/>
                            </Box>
                            <Box className='sixth-row'>
                                <CreateTextFields fields={registerTextfield.slice(8,9)} formField={formData} onChange={handleFieldChange} isSubmitted={isSubmitted}/>
                                <Box className='input-container'>
                                      <InputLabel sx={{color:'black', marginBottom:1}}>
                                        Business Document
                                        <Tooltip title={'Only pdf files are supported'}>
                                            <IconButton size="small">
                                            <QuestionMarkIcon sx={{fontSize:16}}/>
                                            </IconButton>
                                        </Tooltip>
                                      </InputLabel>
                                      <Box className='inputy'>
                                        <input id='inputy'  type='file' className='fileInput' name='business_document' onChange={handleFileChange}></input>
                                        {files?.name && <Box onClick={()=>{ setFiles({}); document.getElementById('inputy').value='' }} ><CloseIcon/></Box>}
                                      </Box>
                                      {(fileError || fileRequiredError) ?<Box className='redError'><InputLabel>{fileError ? 'Only Pdf files are supported' : 'File is required'}</InputLabel></Box>:<></>}
                                </Box>         
                            </Box>
                            <Box className='seventh-row'>
                                <CreateTextFields fields={registerTextfield.slice(9,11)} formField={formData} onChange={handleFieldChange} isSubmitted={isSubmitted}/>
                            </Box>
                            <Box className='eigth-row'>
                            </Box>
                            <Box className='ninth-row'>
                                <button type='submit'>REGISTER</button>
                                {/* <Button onSubmit={registerFunction}>REGISTER</Button> */}
                            </Box>
                            </form>
                        </Box>
                        

            }

            {/* THIS EXIST FOR ALL */}
            <Box className='login-footer'>
                <Box className="left-footer">© {currentYear} Made with Love By <Box component={'span'} className='purple'>AVAH Services</Box></Box>
                {/* ONLY SHOW IF NOT IN MOBILE VIEW */}
                {/* {!isMobile && (
                <Box className="right-footer">
                    <Box mr={2}>About Us</Box>
                    <Box mr={2}>Terms & Conditions</Box>
                    <Box mr={2}>FAQ's</Box>
                    <Box mr={2}>Privacy Policy</Box>
                    <Box mr={2}>Contact Us</Box>
                </Box>)} */}
            </Box>
        </Grid>
        {/* THIS IS RIGHT SIDE CONTAINER */}
        <Grid item className="right-side-container">
            <img src={AvahTransparent} alt="" />
        </Grid>
    </Grid>
    {snackbar}
    {loadingIndicator}
    {(formData.success || formData?.error) && <SuccessLoader success={formData?.success}/>}
    </>

  )
}

export default RaeesLoginComponent